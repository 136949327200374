import {Page, Content} from '@backstage/core-components';
import {
  TemplateBackstageLogoIcon,
  HomePageStarredEntities,
  HomePageToolkit,
  CustomHomepageGrid,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  Tool,
} from '@backstage/plugin-home';
import {HomePageSearchBar} from '@backstage/plugin-search';
import React from 'react';

const defaultConfig = [
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 0,
    width: 24,
    height: 2,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 0,
    y: 1,
    width: 5,
    height: 4,
  },
  {
    component: 'HomePageTopVisited',
    x: 5,
    y: 1,
    width: 5,
    height: 4,
  },
  {
    component: 'HomePageStarredEntities',
    x: 0,
    y: 2,
    width: 6,
    height: 4,
  },
  {
    component: 'HomePageToolkit',
    x: 6,
    y: 6,
    width: 4,
    height: 4,
  },
];

const tools: Tool[] = [
  {
    label: 'Backstage docs',
    url: 'https://docs.backstage.ecoinfra.io/docs',
    icon: <TemplateBackstageLogoIcon />,
  },
];
export const HomePage = () => {
  return (
    <Page themeId="home">
      <Content>
        <CustomHomepageGrid config={defaultConfig}>
          <HomePageSearchBar />
          <HomePageRecentlyVisited />
          <HomePageTopVisited />
          <HomePageToolkit tools={tools} />
          <HomePageStarredEntities />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );
};
