import {Entity, stringifyEntityRef} from '@backstage/catalog-model';
import {Button} from '@material-ui/core';
import {FileCopy} from '@material-ui/icons';
import React from 'react';

export const EntityRefCopyButton = (props: {
  entity?: Entity;
  stringifiedRef?: string;
}) => {
  return (
    <Button
      aria-label="Copy Ref"
      color="primary"
      variant="outlined"
      startIcon={<FileCopy />}
      onClick={() => {
        if (props.stringifiedRef) {
          navigator.clipboard.writeText(props.stringifiedRef);
        } else if (props.entity) {
          navigator.clipboard.writeText(stringifyEntityRef(props.entity));
        }
      }}
    >
      Copy Ref
    </Button>
  );
};
