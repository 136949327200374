import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import {Link} from '@backstage/core-components';
import {IconComponent} from '@backstage/core-plugin-api';

import {ScmIntegrationIcon} from '@backstage/integration-react';
import {GoogleIcon} from '../../icons/Google';
import {SvgIconProps} from '@material-ui/core';
import {ConfluenceIcon} from '../../icons/Confluence';

const useStyles = makeStyles({
  svgIcon: {
    display: 'inline-block',
    '& svg': {
      display: 'inline-block',
      fontSize: 'inherit',
      verticalAlign: 'baseline',
    },
  },
});

export function IconLink(props: {
  href: string;
  text?: string;
  Icon?: IconComponent;
  onlyIcon?: boolean;
}) {
  const {href, text, Icon, onlyIcon = false} = props;
  const classes = useStyles();

  if (onlyIcon) {
    return (
      <Box display="flex">
        <Box mr={1} className={classes.svgIcon}>
          <Typography component="div">
            <Link to={href} target="_blank" rel="noopener">
              {Icon ? <Icon /> : <LanguageIcon />}
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex">
      <Box mr={1} className={classes.svgIcon}>
        <Typography component="div">
          {Icon ? <Icon /> : <LanguageIcon />}
        </Typography>
      </Box>
      <Box flexGrow="1">
        <Link to={href} target="_blank" rel="noopener">
          {text || href}
        </Link>
      </Box>
    </Box>
  );
}

export const IntegrationIcon = (link: string, props?: SvgIconProps) => {
  if (link.includes('github')) {
    return <ScmIntegrationIcon type="github" {...props} />;
  }

  if (link.includes('drive')) {
    return <GoogleIcon {...props} />;
  }
  if (link.includes('confluence')) {
    return <ConfluenceIcon {...props} />;
  }

  return <ScmIntegrationIcon />;
};
