import {
  ANNOTATION_EDIT_URL,
  EntityLink,
  RELATION_MEMBER_OF,
  UserEntity,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import LanguageIcon from '@material-ui/icons/Language';

import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import {IconComponent, useApp} from '@backstage/core-plugin-api';
import {Divider, Typography, colors, makeStyles} from '@material-ui/core';
import {EntityRefCopyButton} from './EntityRefCopyButton';

const useStyles = makeStyles({
  titleDisabled: {
    color: colors.grey[600],
  },
});

const CardTitle = (props: {title?: string; disabledClassName?: string}) =>
  props.title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon className={props.disabledClassName} fontSize="inherit" />
      <Box ml={1} className={props.disabledClassName}>
        {props.title}
      </Box>
    </Box>
  ) : null;

export const CustomUserProfileCard = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const {entity: user} = useEntity<UserEntity>();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const entityMetadataEditUrl =
    user.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const isInactiveUser =
    user.metadata.annotations?.['deeplay.io/scim-is-active'] === 'false';

  const {
    metadata: {name: metaName, description, links},
    spec: {profile},
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  const styles = useStyles();

  return (
    <InfoCard
      title={
        <CardTitle
          title={displayName}
          disabledClassName={isInactiveUser ? styles.titleDisabled : undefined}
        />
      }
      subheader={
        <Typography variant="caption" color="error">
          {isInactiveUser ? 'User inactive' : description}
        </Typography>
      }
      variant={props.variant}
      action={<EntityRefCopyButton entity={user} />}
      actions={[
        <>
          {entityMetadataEditUrl && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={entityMetadataEditUrl}
            >
              <EditIcon />
            </IconButton>
          )}
        </>,
      ]}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref ?? ''}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemIcon>
                <Tooltip title="Member of">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                />
              </ListItemText>
            </ListItem>

            {props?.showLinks && <LinksGroup links={links} />}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

const WebLink = ({
  href,
  Icon,
  text,
}: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) => (
  <ListItem key={href}>
    <ListItemIcon>{Icon ? <Icon /> : <LanguageIcon />}</ListItemIcon>
    <ListItemText>
      <Link to={href}>{text}</Link>
    </ListItemText>
  </ListItem>
);

export const LinksGroup = ({links}: {links?: EntityLink[]}) => {
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  if (links === undefined) {
    return null;
  }

  return (
    <>
      <Divider />
      {links.map(link => {
        return (
          <WebLink
            key={link.url}
            href={link.url}
            text={link.title}
            Icon={iconResolver(link.icon)}
          />
        );
      })}
    </>
  );
};
