import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './ADR.v1alpha.schema.json';

/**
 * Backstage catalog ADR kind Entity. ADR is used
 * to store important decisions or proposals,
 * related to other Entities.
 *
 * @public
 */
export interface ADREntityV1alpha extends Entity {
  /**
   * The apiVersion string of the ADR.
   */
  apiVersion: 'deeplay.io/adr';
  /**
   * The kind of the entity
   */
  kind: 'ADR';
  /**
   * The specification of the ADR Entity
   */
  spec: {
    /**
     * Document title.
     */
    title: string;
    /**
     * Original document name.
     */
    documentName?: string;

    /**
     * Link to original document.
     */
    linksToPreviousVersions?: string[];

    /**
     * Optional date of the record.
     */
    recordDate?: string;

    /**
     * Optional status of the record.
     */
    status?: string;

    /**
     * Optional list of references to related entities.
     */
    relatedEntityRefs?: string[];

    authors?: string[];

    /**
     * Optional path to the document at the source,
     * depending on source type could be repository name or path at Google Drive.
     */
    path?: string;
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link ADREntityV1alpha}.
 *
 * @public
 */
export const ADREntityV1alphaValidator: KindValidator = {
  async check(data: Entity) {
    return validator(data) === data;
  },
};

/**
 * Typeguard for filtering entities and ensuring adr entities
 * @public
 */
export const isADREntityV1alpha = (
  entity: Entity,
): entity is ADREntityV1alpha =>
  entity.apiVersion === 'deeplay.io/adr' && entity.kind === 'ADR';
